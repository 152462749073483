<template>
  <div class="redirect">
    <h2 class="redirect__heading">
      {{ itemDetails.url ? "Redirect " : "" }}Notice
    </h2>
    <div class="redirect__description">
      <div
        v-if="itemDetails.url"
        class="redirect__description__content py-50 py-md-1"
      >
        <p class="text-break">
          The page you were on is trying to send you to
          <a :href="itemDetails.url" class="cursor-pointer">{{
            itemDetails.url
          }}</a>
        </p>
        <!-- <p>
          If you do not want to visit that page, you can
          <a href="#" class="cursor-pointer">return to the previous page.</a>
        </p> -->
      </div>
      <div
        class="redirect__description__content py-75 py-md-2"
        v-if="itemDetails && itemDetails.tags && itemDetails.tags.length > 0"
      >
        <p>
          The below Video is suggested to you because your Skill/Behavior
          development priority is set with tags:
          <span
            class="tag d-flex align-items-center flex-wrap"
            v-if="itemDetails.tags"
          >
            <span
              class="tag__text m-50"
              v-for="(tag, index) in itemDetails.tags"
              :key="index"
              >#{{ tag }}</span
            >
            &nbsp;
          </span>
        </p>
      </div>
      <div
        class="course__content d-flex align-items-start py-75 py-md-1 py-lg-2"
      >
        <div
          class="course-img position-relative d-flex align-items-center justify-content-center"
        >
          <img
            class="w-100 h-100"
            :src="itemDetails.imageUrl ? itemDetails.imageUrl : randomFeedImage"
            @error="randomFeedImage"
          />
          <div
            class="position-absolute d-flex align-items-center justify-content-center"
          >
            <img
              v-if="itemDetails.format === 'WATCH'"
              class="img__logo"
              src="@/assets/images/redirect-page/play.svg"
              alt="WATCH"
            />
            <img
              v-else-if="itemDetails.format === 'LISTEN'"
              class="img__logo"
              :src="require(`@/assets/images/redirect-page/headphone.svg`)"
              alt="LISTEN"
            />

            <img
              v-else-if="itemDetails.format === 'PARTICPATE'"
              class="img__logo"
              src="@/assets/images/library/participation.svg"
              alt="Participation"
            />
          </div>
        </div>
        <div class="details">
          <div class="details__title d-flex align-items-center">
            <b-avatar
              :src="itemDetails.sourceLogoUrl"
              :alt="itemDetails.source ? itemDetails.source : 'GetBoarded'"
              @error="altSrcImage"
              variant="light"
              size="2.5em"
              class="mr-50"
            />
            <p>{{ itemDetails.source }}</p>
          </div>
          <div class="details__heading d-flex align-items-start">
            <h4 class="clip-text">{{ itemDetails.title }}</h4>
            <img
              v-if="itemDetails.type"
              class="details__logo cursor-pointer"
              :src="
                require(`@/assets/images/redirect-page/${itemDetails.type}.svg`)
              "
            />

            <!-- <feather-icon
              class="cursor-pointer"
              size="22"
              icon="AlertCircleIcon"
            /> -->
          </div>
          <div class="details__text text-justify clip-text clip-text-3">
            <p>
              {{ itemDetails.description }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="itemDetails.url"
        class="redirect__description__content py-75 py-md-2"
      >
        <p>
          You can rate the relevance of the content suggestion and the content
          quality from the web page or the email you came for.
        </p>
        <p v-if="count === 0">Redirecting...</p>
        <p v-else>This page will redirect itself in {{ count }} seconds.</p>
      </div>

      <div
        v-if="isAltfEvent"
        class="mt-1 w-100 d-flex align-items-center justify-content-center"
      >
        <div
          class="event__btn px-75 py-50 px-md-1 py-md-75 cursor-pointer"
          @click="joinEvent"
        >
          Join Event
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      itemDetails: {},
      count: 5,
      isAltfEvent: false,
    };
  },
  components: {
    BAvatar,
  },
  mounted() {
    this.getItemById();
  },
  methods: {
    reverseTimeCounter() {
      setInterval(() => {
        this.count = this.count >= 1 ? this.count - 1 : 0;
      }, 1000);
    },
    getItemById() {
      const { itemId, type, gbuid, gborgid, redirectsourceid } =
        this.$route.query;

      if (itemId.includes("altf")) {
        this.isAltfEvent = true;
        this.$store
          .dispatch("library/getCustomItemById", {
            itemId,
            gborgid,
            gbuid,
          })
          .then((res) => {
            this.itemDetails = res.data;
            if (
              this.itemDetails?.status === "do" &&
              redirectsourceid !== "ORG_REVIEW"
            ) {
              this.$store.dispatch("library/updateItemStatus", {
                getboardedId: gbuid,
                itemId: itemId,
                type: this.itemDetails.type || this.itemDetails.itemType,
                status: "doing",
              });
            }

            this.$store.dispatch("library/postItemAnalytics", {
              gtbrddOrganizationId: gborgid,
              gtbrddUserId: gbuid,
              feedItemId: itemId,
              source: redirectsourceid ? redirectsourceid : "LIBRARY",
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "gtm.load",
              ...this.$route.query,
              itemDetails: { ...this.itemDetails },
            });
            // this.reverseTimeCounter();
            // setTimeout(() => {
            //   window.location.href = res.url;
            // }, 4000);
          })
          .catch(() => {
            return;
          });
      } else {
        this.$store
          .dispatch("library/getItemById", {
            itemId,
            type,
            gbuid,
          })
          .then((res) => {
            this.itemDetails = res;
            if (
              this.itemDetails?.status === "do" &&
              redirectsourceid !== "ORG_REVIEW"
            ) {
              this.$store.dispatch("library/updateItemStatus", {
                getboardedId: gbuid,
                itemId: itemId,
                type: this.itemDetails.type,
                status: "doing",
              });
            }

            this.$store.dispatch("library/postItemAnalytics", {
              gtbrddOrganizationId: gborgid,
              gtbrddUserId: gbuid,
              feedItemId: itemId,
              source: redirectsourceid ? redirectsourceid : "LIBRARY",
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "gtm.load",
              ...this.$route.query,
              itemDetails: { ...this.itemDetails },
            });
            this.reverseTimeCounter();
            setTimeout(() => {
              window.location.href = res.url;
            }, 4000);
          })
          .catch(() => {
            return;
          });
      }
    },
    randomFeedImage(event) {
      let i = 15; // i = number of given random images
      let alternateImgUrl = require(`@/assets/images/library/blank-feed/${[
        Math.floor(Math.random() * i),
      ]}.png`);
      event.target.src = alternateImgUrl;
      return alternateImgUrl;
      // this.$set(this.libraryItem, index, item);
    },
    altSrcImage(e) {
      return (e.target.src = require(`@/assets/images/library/getboarded-logo.svg`));
    },
    joinEvent() {
      const PortugalTimeZone = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Lisbon",
        hour12: false, // Use 24-hour format
      });

      const portugalTime = new Date(PortugalTimeZone);
      const currentHour = portugalTime.getHours();
      const currentMinutes = portugalTime.getMinutes();
      if (
        (currentHour === 9 && currentMinutes >= 30) ||
        (currentHour > 9 && currentHour < 12) ||
        (currentHour === 12 && currentMinutes <= 50)
      ) {
        window.location.href =
          "https://dev.stream.video.wiki/live/p6wi-rysr-epih-2el9";
      }

      // else if (
      //   currentHour === 14 ||
      //   (currentHour > 14 && currentHour < 17) ||
      //   (currentHour === 17 && currentMinutes <= 45)
      // ) {
      //   window.location.href = `https://dev.stream.video.wiki/live/ne7o-u0ra-2yr7-wlb4`;
      // }
      else {
        window.location.href = `https://dev.stream.video.wiki/live/ne7o-u0ra-2yr7-wlb4`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.redirect {
  background: #ffffff;
  min-height: 100vh;

  &__heading {
    background: #f9e7ca;
    padding: 1em 3.15em;
    font-size: 2.5em;
    font-weight: 700;
    color: #101828;
  }

  &__description {
    padding: 1.5em 8em;

    &__content {
      width: 81%;

      p {
        font-size: 1.25em;
      }

      a {
        text-decoration: underline;
      }

      .tag {
        &__text {
          padding: 0.35em 0.75em;
          background: #f2f4f7;
          border-radius: 2em;
          font-weight: 600;
          color: #101828;
          font-size: 1em;
          text-transform: capitalize;
        }
      }
    }

    .course {
      &__content {
        .course-img {
          margin-right: 2em;
          width: 15%;
          overflow: hidden;
          height: 12em;

          img {
            border-radius: 6px;
            object-fit: cover;
          }
        }

        .details {
          width: 85%;

          &__title {
            margin-bottom: 0.85em;

            p {
              font-size: 1.25em;
              margin-bottom: 0;
              font-weight: 600;
              color: #344054;
            }
          }

          &__heading {
            width: 75%;
            margin-bottom: 0.85em;

            h4 {
              margin-right: 0.5em;
              font-weight: 600;
              font-size: 1.5em;
              color: #101828;
              margin-bottom: 0;
            }
          }

          &__text {
            width: 75%;

            p {
              font-size: 1.125em;
            }
          }

          &__logo {
            margin-right: 0.75em;
            width: 1.5em;
          }
        }
      }
    }
  }

  .clip-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &-3 {
      -webkit-line-clamp: 3;
    }
  }
}

.event__btn {
  background: linear-gradient(90deg, #fb8c00, #f76c5e 50%, #ba5bc2);
  border: 0 solid #d0d5dd;
  border-radius: 0.75rem;
  color: #ffffff;
  font-size: 1.5rem;
  width: fit-content;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .redirect {
    &__description {
      .course {
        &__content {
          .course-img {
            width: 30%;
            margin-right: 1.75em;

            &__logo {
              width: 2em;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .redirect {
    &__heading {
      padding: 0.75em 1.75em;
      font-size: 2em;
    }

    &__description {
      padding: 0.75em 3.5em;

      &__content {
        width: 100%;
        padding: 2em 0;

        p {
          font-size: 1em;
        }
      }

      .course {
        &__content {
          .course-img {
            width: 25%;
            margin-right: 1.75em;

            &__logo {
              width: 2em;
            }
          }

          .details {
            width: 70%;

            &__title {
              p {
                font-size: 1.125em;
              }
            }

            &__heading {
              width: 100%;

              h4 {
                font-size: 1.25em;
              }
            }

            &__text {
              width: 100%;

              p {
                font-size: 1em;
              }
            }

            &__logo {
              width: 1.25em;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .redirect {
    &__heading {
      padding: 0.5em 1.5em;
      font-size: 1.5em;
    }

    &__description {
      padding: 0.5em 2.5em;

      &__content {
        p {
          font-size: 0.85em;
          line-height: 1.25em;
          margin-bottom: 0.5em;
        }
      }

      .course {
        &__content {
          padding: 0;
          flex-direction: column;

          .course-img {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1em;

            &__logo {
              width: 1.75em;
            }
          }

          .details {
            width: 100%;

            &__title {
              p {
                font-size: 1em;
              }
            }

            &__heading {
              h4 {
                font-size: 1.125em;
              }
            }

            &__text {
              p {
                font-size: 0.85em;
              }
            }

            &__logo {
              margin-right: 0.5em;
            }
          }
        }
      }
    }
  }

  .event__btn {
    font-size: 1.25rem;
  }
}
</style>
